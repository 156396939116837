'use client';

import { User } from "firebase/auth";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { FilterType } from "@/types/filter";

// update this interface if state requires more props than just children
interface StateProviderProps {
  children: ReactNode;
}
const StateContext = createContext<any | undefined>(undefined);
const StateProvider: React.FC<StateProviderProps> = ({
  children
}) => {
  const [reviewResult, setReviewResult] = useState({});
  const [products, setProducts] = useState([]);
  const [neighbors, setNeighbors] = useState({});
  const [recommendationList, setRecommendationList] = useState({});
  const [headerSearch, setHeaderSearch] = useState<boolean>(false);
  const [productBasicInfoList, setProductBasicInfoList] = useState([]);
  const [productDetailInfoList, setProductDetailInfoList] = useState([]);
  const [productReviewInfoList, setProductReviewInfoList] = useState([]);
  const [review_summaryList, setReview_summaryList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [otherProductInfo, setOtherProductInfo] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<number>(1);
  const [product_all_info, setProduct_all_info] = useState([]);
  const [productAllInfoSource, setProductAllInfoSource] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowScoreExplanation, setIsShowScoreExplanation] = useState<boolean>(false);
  const [scoreExplanationPosition, setScoreExplanationPosition] = useState({
    top: 0,
    right: 0
  });
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isFilterCleaned, setIsFilterCleaned] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterType>({
    newPriceHighBar: 5000,
    newPriceLowBar: 0,
    usedPriceHighBar: 5000,
    usedPriceLowBar: 0,
    category: [],
    brand: [],
    sortBy: ""
  });
  const updateFilters = (newFilters: Partial<FilterType>) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  };
  const value = {
    reviewResult,
    setReviewResult,
    neighbors,
    setNeighbors,
    products,
    setProducts,
    recommendationList,
    setRecommendationList,
    headerSearch,
    setHeaderSearch,
    productBasicInfoList,
    setProductBasicInfoList,
    productDetailInfoList,
    setProductDetailInfoList,
    productReviewInfoList,
    setProductReviewInfoList,
    review_summaryList,
    setReview_summaryList,
    videoList,
    setVideoList,
    blogList,
    setBlogList,
    otherProductInfo,
    setOtherProductInfo,
    isLoading,
    setIsLoading,
    product_all_info,
    setProduct_all_info,
    productAllInfoSource,
    setProductAllInfoSource,
    pagination,
    setPagination,
    searchTerm,
    setSearchTerm,
    isShowModal,
    setIsShowModal,
    isShowScoreExplanation,
    setIsShowScoreExplanation,
    scoreExplanationPosition,
    setScoreExplanationPosition,
    currentUser,
    setCurrentUser,
    filters,
    updateFilters,
    isFilterCleaned,
    setIsFilterCleaned
  };
  return <StateContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="StateProvider" data-sentry-source-file="StateProvider.tsx">{children}</StateContext.Provider>;
};
const useReviewResult = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useReviewResult must be used within a StateProvider");
  }
  return [context.reviewResult, context.setReviewResult];
};
const useRecommendationList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useRecommendationList must be used within a StateProvider");
  }
  return [context.recommendationList, context.setRecommendationList];
};
const useNeighbors = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useNeighbors must be used within a StateProvider");
  }
  return [context.neighbors, context.setNeighbors];
};
const useProducts = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useProducts must be used within a StateProvider");
  }
  return [context.products, context.setProducts];
};
const useHeaderSearch = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useHeaderSearch must be used within a StateProvider");
  }
  return [context.headerSearch, context.setHeaderSearch];
};
const useProductBasicInfoList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useProductBasicInfoList must be used within a StateProvider");
  }
  return [context.productBasicInfoList, context.setProductBasicInfoList];
};
const useProductDetailInfoList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useProductDetailInfoList must be used within a StateProvider");
  }
  return [context.productDetailInfoList, context.setProductDetailInfoList];
};
const useProductReviewInfoList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useProductReviewInfoList must be used within a StateProvider");
  }
  return [context.productReviewInfoList, context.setProductReviewInfoList];
};
const useReview_summaryList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useReview_summaryList must be used within a StateProvider");
  }
  return [context.review_summaryList, context.setReview_summaryList];
};
const useVideoList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("usevideoList must be used within a StateProvider");
  }
  return [context.videoList, context.setVideoList];
};
const useBlogList = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useblogList must be used within a StateProvider");
  }
  return [context.blogList, context.setBlogList];
};

//This state will store all the other information from the response, including:
// 1. product evaluation tag list
// 2. product score under specific use case
const useOtherProductInfo = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useOtherProductInfo must be used within a StateProvider");
  }
  return [context.otherProductInfo, context.setOtherProductInfo];
};
const useIsLoading = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useIsLoading must be used within a StateProvider");
  }
  return [context.isLoading, context.setIsLoading];
};
const useProductAllInfo = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useProductAllInfo must be used within a StateProvider");
  }
  return [context.product_all_info, context.setProduct_all_info];
};
const usePagination = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("usePagination must be used within a StateProvider");
  }
  return [context.pagination, context.setPagination];
};
const useSearchTerm = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useSearchTerm must be used within a StateProvider");
  }
  return [context.searchTerm, context.setSearchTerm];
};
const useIsShowModal = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useIsShowModal must be used within a StateProvider");
  }
  return [context.isShowModal, context.setIsShowModal];
};
const useIsShowScoreExplanation = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useIsShowScoreExplanation must be used within a StateProvider");
  }
  return [context.isShowScoreExplanation, context.setIsShowScoreExplanation];
};
const useScoreExplanationposition = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useScoreExplanationposition must be used within a StateProvider");
  }
  return [context.scoreExplanationPosition, context.setScoreExplanationPosition];
};
const useCurrentUser = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCurrentUser must be used within a StateProvider");
  }
  return [context.currentUser, context.setCurrentUser];
};
const useFilters = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a StateProvider");
  }
  return [context.filters, context.updateFilters];
};

//This state is used to store the status of the filter, whether it is cleaned or not, when the user clicks the "clear all" button,
//the filter will be cleaned and the products will be reloaded
const useIsFilterCleaned = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useIsFilterCleaned must be used within a StateProvider");
  }
  return [context.isFilterCleaned, context.setIsFilterCleaned];
};

//This state is used to store the product information from the response, different from the productAllInfo state, 
//this state will not be updated when the filter is changed.
const useProductAllInfoSource = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useProductAllInfoSource must be used within a StateProvider");
  }
  return [context.productAllInfoSource, context.setProductAllInfoSource];
};
export { StateContext, StateProvider, useReviewResult, useRecommendationList, useNeighbors, useProducts, useHeaderSearch, useProductBasicInfoList, useProductDetailInfoList, useProductReviewInfoList, useReview_summaryList, useVideoList, useBlogList, useOtherProductInfo, useIsLoading, useProductAllInfo, useProductAllInfoSource, usePagination, useSearchTerm, useIsShowModal, useIsShowScoreExplanation, useScoreExplanationposition, useCurrentUser, useFilters, useIsFilterCleaned };