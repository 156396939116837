"use client";

import React from "react";
import { useEffect, useRef } from "react";
import { useCurrentUser } from "../../context/StateProvider";
import { signOut } from "firebase/auth";
import sign_in_logo from "../../../../public/logo/signin_logo.png";
import { auth, db } from "../../../../firebase";
import { collection, getDocs, query, where } from 'firebase/firestore';
import ReactGA from "react-ga4";
import styles from "./Header.module.css";
import Box from "@mui/material/Box";
import Link from "next/link";
import LoginReminder from "./LoginReminder";
import Image from "next/image";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useRouter, usePathname } from "next/navigation";
type ClientIdResult = {
  clientId: string | null;
  hasUid: boolean;
};
const LoginPart = () => {
  const [currentUser, setCurrentUser] = useCurrentUser();
  const reminderRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();
  const router = useRouter();
  const TRACKING_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID as string;
  const handleAuthentication = () => {
    if (currentUser) {
      signOut(auth);
      setCurrentUser(null);
    }
  };

  // use Cookie to keep track of reminder count
  const getReminderCount = () => {
    const reminderCookie = document.cookie.split("; ").find(row => row.startsWith("reminder_count="));
    if (reminderCookie) {
      return parseInt(reminderCookie.split("=")[1], 10);
    }
    return 0;
  };

  // Check if client ID and UID is paired
  const checkIfClientIdHasUid = async (clientId: string) => {
    const q = query(collection(db, "users"), where("clientId", "==", clientId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  // Check files in Cloud Firestore, 
  // if pair exists => old user; non-exist => new user.
  async function getClientId(): Promise<ClientIdResult> {
    return new Promise(resolve => {
      gtag('get', TRACKING_ID, 'client_id', async result => {
        const clientId = result as string;
        // console.log('Client ID:', clientId);
        if (clientId) {
          const hasUid = await checkIfClientIdHasUid(clientId).catch(error => {
            console.error("Check UID error: ", error);
            return false;
          });
          // console.log('Has UID:', hasUid);
          resolve({
            clientId,
            hasUid
          });
        } else {
          resolve({
            clientId: null,
            hasUid: false
          });
        }
      });
    });
  }

  // Track reminder count
  const incrementReminderCount = () => {
    const count = getReminderCount() + 1;
    document.cookie = `reminder_count=${count}; path=/; max-age=` + 60 * 60 * 24 * 30; // 1 month
  };
  useEffect(() => {
    // Use GA to identify user type: new or old. Display login reminder correspondly.
    const initializeGA = () => {
      if (typeof window !== "undefined") {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname
        });
      }
    };
    const checkUserStatus = async () => {
      const {
        clientId,
        hasUid
      } = await getClientId().catch(error => {
        console.error("Get client ID error: ", error);
        return {
          clientId: null,
          hasUid: false
        };
      });
      if (!clientId) return;
      if (!hasUid && !currentUser && getReminderCount() < 6) {
        // Check the status after element is rendered to DOM
        setTimeout(() => {
          if (reminderRef.current) {
            reminderRef.current.style.display = "flex";
            incrementReminderCount();
          }
        }, 0);
      }
    };
    initializeGA();
    checkUserStatus();
  }, [currentUser]);
  return <>
            <div className={styles.header_nav}>
				{currentUser && <>
						<Box sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block"
          }
        }}>
							<div id="greeting" className={styles.header_option}>
								Hello {!currentUser ? "Guest," : currentUser.email ? currentUser.email : currentUser.displayName}
							</div>
						</Box>
					</>}

				<Link href={currentUser ? "/" : "/login"} data-sentry-element="Link" data-sentry-source-file="LoginPart.tsx">
					<div className={styles.header_option} onClick={handleAuthentication}>
						{currentUser ? <AccountCircleIcon style={{
            transform: "scale(1.25)"
          }} /> : <Image className={styles.header_signin_logo} src={sign_in_logo.src} alt="SIGNIN LOGO" width={100} height={30} />}
					</div>
				</Link>
			</div>

			{/*!currentUser && pathname !== '/login' && pathname !== '/signup' &&  <LoginReminder ref={reminderRef} />*/}
        </>;
};
export default LoginPart;