"use client";

import React from "react";
import Image from 'next/image';
import logo from "../../../../public/logo/logo.png";
import search from "../../../../public/logo/search-white.png";
import styles from "./Header.module.css";
import { useSearchTerm } from "../../context/StateProvider";
import { useRouter } from "next/navigation";

// Only load features from Header, but not reloading the page
interface HeaderProps {
  disableSearch?: boolean;
}
const LogoPart: React.FC<HeaderProps> = ({
  disableSearch = false
}) => {
  const [searchTerm, setSearchTerm] = useSearchTerm();
  const router = useRouter();

  //This will be trigger when click the search button
  const handleClickLogo = () => {
    //console.log("Click the logo");
    if (!disableSearch) {
      router.push(`/`);
      setSearchTerm("");
    }
  };
  return <div className="flex cursor-pointer flex-row h-full items-center" onClick={handleClickLogo} data-sentry-component="LogoPart" data-sentry-source-file="LogoPart.tsx">
			<Image className={styles.header_logo} src={logo.src} alt="COMMENTLY LOGO" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="LogoPart.tsx" />

			<p className={styles.header_logo_text}>Commentlyai</p>

			<Image className="mx-0 h-full w-fit" src={search.src} alt="search" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="LogoPart.tsx" />
		</div>;
};
export default LogoPart;